import { Config } from "./config";
export const Base_Url = 'https://iq-services-reconciliation-int.azure.development.k8s.iqmetrix.net/v1/';
export const Auth_Client_ID = 'AdvancedReconciliationsTestClient1';

const getCompanyId = () => {
  return localStorage.getItem('companyId');
}

export const API_Endpoints = () => {
  const companyId = getCompanyId();
  return {
    getBaseUrl:(process.env.REACT_APP_MOCK_BASE_URL || Base_Url).concat(`companies/${companyId}/`),
    // reconciliation
    reconciliationBaseURL: (process.env.REACT_APP_MOCK_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/`),
    getReconciliationURL: (process.env.REACT_APP_MOCK_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/`),
    getFileUploadURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/`),
    createReconciliationURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/create`),
    getReconciliationByIdURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/`),
    getNotificationsURL: (process.env.REACT_APP_MOCK_BASE_URL || Base_Url).concat(`companies/${companyId}/reconciliations/`),
    getAllVendorURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/vendors/list`),
    

    // templates
    getTemplateURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/`),
    getTemplateListURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/list`),
    createTemplateURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/create`),
    updateTemplateURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/update`),
    getTemplateSummaryURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/summaries`),
    getRuleSetForTemplateURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates`),
    getSourceAndTargetURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates/`),
    deleteTemplateURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/templates`),
    
    // rules
    getRuleSetSummaryURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/rulesets`),
    getDataForRuleURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/rulesets`),
    createRuleSetURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/rulesets/create`),
    updateRuleSetURL: (process.env.REACT_APP_IQ_BASE_URL || Base_Url).concat(`companies/${companyId}/rulesets/update`),

    getUserInfoURL: (process.env.REACT_APP_MOCK_BASE_URL || Base_Url).concat(`companies/${companyId}/userinfo`),
  }
};

export const AuthEndpoint = (redirectUri: string) =>
  `${Config.authUrl}/v1/oauth2/auth?response_type=token&client_id=${Auth_Client_ID}&redirect_uri=${redirectUri}`;

export const LogoutEndpoint = (token: string, returnUrl: string) =>
  `${Config.authUrl}/logout?accessToken=${token}&returnUrl=${returnUrl}`;
export const TokenByCodeEndpoint = (token: string) => `${Config.authUrl}/v1/oauth2/tokeninfo?access_token=${token}`;

export const meEndpoint = () => `${Config.authUrl}/v1/me`;
export const parentEntityEndpoint = () => `${Config.entityUrl}`;
