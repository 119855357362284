import { API_Endpoints } from "../constants/endpoints";
import { getAPI, postAPI } from "./http.service";
import { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { ErrorContext } from "../components/utils/errorContext";
import dayjs, { Dayjs } from "dayjs";
import { LoaderContext } from "../components/utils/loaderContext";
import { NotificationContext } from "../components/utils/notificationContext";

export class ReconciliationService {
  private endpoints = API_Endpoints();
  private notificationContext = useContext(NotificationContext);
  private errorContext = useContext(ErrorContext);
  private loaderContext = useContext(LoaderContext);

  getReconciliation = async (params?: any) => {
      this.loaderContext.setLoader(true);
      return await getAPI(this.endpoints.getReconciliationURL, params)
          .then((res) => {
              return res.data;
      })
      .catch((err) => {
          this.notificationContext.setNotification({...err})
      }).finally(()=>this.loaderContext.setLoader(false));
    };

    commitFile = async (id:string) => {
      this.loaderContext.setLoader(true);
      const endpoint = this.endpoints.getFileUploadURL + id + "/commitupload";
      return await postAPI(endpoint, {})
        .then((res: AxiosResponse) => {
          return res;
        })
        .catch((err) => {
          this.notificationContext.setNotification({...err})
      }).finally(()=> this.loaderContext.setLoader(false));
    };
  
    allocateUpload = async (id:string) => {
      this.loaderContext.setLoader(true);
      const endpoint = this.endpoints.getFileUploadURL + id + "/allocateUpload";
      return await postAPI(endpoint, {})
        .then((res: AxiosResponse) => {
          return res;
        })
        .catch((err) => {
          this.notificationContext.setNotification({...err})
      }).finally(()=> this.loaderContext.setLoader(false));
    };
  

  createReconciliation = async (data: any) => {
    if (!dayjs(data.dateRange[0]).isValid() || !dayjs(data.dateRange[1]).isValid()) {
      throw new Error("Invalid date format in dateRange");
    }

    const periodStartDateUtc = dayjs(data.dateRange[0]).format("YYYY-MM-DD");
    const periodEndDateUtc = dayjs(data.dateRange[1]).format("YYYY-MM-DD");

    // Constructing the body dynamically
    const body = {
        name: data?.name,
        templateId: data?.template,
        ruleSetId: data?.ruleset,
        sourceDataFilter: {
          periodFilter: {
            periodStartDate: periodStartDateUtc,
            periodEndDate: periodEndDateUtc,
          }},
        };
    this.loaderContext.setLoader(true);
    return await postAPI(this.endpoints.createReconciliationURL, body) .then((res: AxiosResponse) => {
        this.loaderContext.setLoader(false);
        return res;
      })
      .catch((err) => {
        this.loaderContext.setLoader(false);
        this.notificationContext.setNotification({...err})
      });
  };

  getVendorList = async () => {
    return await getAPI(this.endpoints.getAllVendorURL).then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.notificationContext.setNotification({...err})
      });
  };

  getNotifications = async(id: number) => {
    return await getAPI(this.endpoints.getReconciliationByIdURL + id).then((res) => {
      return res.data;
    })
    .catch((err) => {
      this.notificationContext.setNotification({...err})
    });
  }

    getUserInfo = async (userId:any) => {
        this.loaderContext.setLoader(true);
        return await getAPI(this.endpoints.getUserInfoURL+userId)
            .then(res => {
                this.loaderContext.setLoader(false);
                return Promise.resolve(res.data);
            }).catch(err => {
                this.loaderContext.setLoader(false);
                this.notificationContext.setNotification({...err})
            });
    }
}