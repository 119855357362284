import {Pagination, Row} from "antd";
import {useEffect, useState} from "react";
import {PaginationPosition} from "antd/es/pagination/Pagination";


export interface PaginationInterface{
    current:number;
    total:number
    pageSize:number
    onChange: (pagination: any, filters?: any, sort?: any) => void
    position?:PaginationPosition
    showQuickJumper?: boolean
    pageSizeOptions: string[] | number[];
    showSizeChanger?:boolean;
}
export function PaginationComponent({current ,total,  pageSize, onChange , pageSizeOptions} : PaginationInterface){

    const [currentPage, setCurrent] = useState(current);
    
    function onChangePage(page:any, newPageSize:any) {
        setCurrent(pageSize !== newPageSize ? 1 : page);
        onChange({page, newPageSize , total:total});
    }

    useEffect(() => {
        setCurrent(current);
    }, [current]);
    return(
        <Row justify={"end"}>
            <Pagination current={currentPage} total={total} pageSize={pageSize} onChange={onChangePage} showQuickJumper={true} showSizeChanger={true}  pageSizeOptions={pageSizeOptions ? pageSizeOptions :[5,10]} />
        </Row>
    )
}

