import { redirectToLogout } from "../utilities";
import { useToken } from "./use-token";

/** Clears the token from cookies and state. Also redirects the user to accounts login page. */
const useLogout = () => {
  const sessionStorageItems = ['notification', 'reconciliationIds'];
  const { getToken, clearToken } = useToken();

  const logout = async () => {
    const token = await getToken();
    sessionStorageItems.forEach(item => sessionStorage.removeItem(item));
    clearToken();
    redirectToLogout(token.value);
  };

  return logout;
};

export { useLogout };
