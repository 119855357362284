import './App.css';
import { ReconciliationHome } from "./ReconciliationHome";
import { useReducer, useState } from "react";
import { AuthProvider, authReducer } from "./auth";
import { SessionHandler } from './handlers/SessionHandler';
import { InteropHandlers } from './handlers/InteropHandlers';
import { NotificationContextProvider } from './components/utils/notificationContext';
import { CustomNotification } from './components/notification/customNotification';
import { ConfigProvider } from 'antd';
import { ThemeService } from '@iqm-ui-library/booster';
import { customThemeConfig } from './theme.config';
function App() {
    const [authState, authDispatch] = useReducer(authReducer, {
        referrer: null,
        isLoggedIn: false,
    });

    const [selectedTheme, setSelectedTheme] = useState(ThemeService.changeTheme('CustomTheme', () => {
    }, customThemeConfig))

    return (
        <div className="App">
            <ConfigProvider theme={selectedTheme}>
                {/* <CordovaApp host={"https://cc-tiger.eng.dxpcloud.net"}/>*/}
                <NotificationContextProvider>
                    <AuthProvider value={{ state: authState, dispatch: authDispatch }}>
                        <SessionHandler>
                            <InteropHandlers>
                                <ReconciliationHome></ReconciliationHome>
                            </InteropHandlers>
                        </SessionHandler>
                    </AuthProvider>
                    <CustomNotification />
                </NotificationContextProvider>
            </ConfigProvider>
        </div>
    );
}

export default App;
