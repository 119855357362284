export const RemoveEmptyAttributes = (obj: any, dontReturnValsArr = [null, undefined]) => {
  // Check weather obj is an array
  if (Array.isArray(obj)) {
    // Creating copy of obj so that index is maintained after splice
    obj.slice(0).forEach((val) => {
      if (dontReturnValsArr.includes(val)) {
        obj.splice(obj.indexOf(val), 1);
      } else if (typeof val === 'object') {
        // Check if array has an object
        RemoveEmptyAttributes(val, dontReturnValsArr);
      }
    });
  } else if (typeof obj === 'object') {
    // Check for object
    Object.keys(obj).forEach((key) => {
      if (dontReturnValsArr.includes(obj[key])) {
        delete obj[key];
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object') {
        RemoveEmptyAttributes(obj[key], dontReturnValsArr);
      }
    });
  }
  return obj;
}

export function isObjectWithArrayEmpty(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return true;
  }

  function isNonEmptyValue(value: any) {
    if (value === null || value === undefined || value === "") {
      return false;
    }
    if (Array.isArray(value)) {
      return value.length > 0 && value.some(isNonEmptyValue);
    }
    if (typeof value === 'object') {
      return !isObjectWithArrayEmpty(value);
    }
    return true;
  }

  for (let key in obj) {
    if (isNonEmptyValue(obj[key])) {
      return false;
    }
  }
  return true;
}

export const isObjectEmpty = (obj: Object) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const dateFormat =(text:string)=>{
  if(!text) return '';
  const date = new Date(text);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
}

export const formtPhoneNumber = (text: string) => {
  return `${text}`.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function replaceToLowerExceptFirst(str: string) {
  return str.replace(/(.)(.*)/, (_, firstChar, rest) => {
    return firstChar + rest.toLowerCase();
  });
}

export function getRandomRGBColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
}

export function moveToFirstIndex(arr: any[], index: number) {
  const item = arr.splice(index, 1);
  arr.unshift(item[0]);
  return arr;
}