import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../utils/errorContext";
import { IQMAlert } from "@iqm-ui-library/booster";
import { SuccessContext } from "../utils/successContext";

export const CustomAlert = (props: any) => {
    const alertStyles: Object = {
        position: 'fixed', right: '20px', bottom: '20px', width: '40%'
    }
    const errorContext = useContext(ErrorContext);
    const successContext = useContext(SuccessContext);
    const [alertObj, setAlertObj] = useState<any>();

    const onClose = () => {
        errorContext?.resetError();
        successContext?.resetSuccess();
    }

    useEffect(() => {
        setAlertObj({
            title: errorContext.error.message || successContext.success.message,
            description: errorContext.error.description || successContext.success.description,
            type: errorContext.error.type || 'success',
        })
    }, [successContext, errorContext])

    return <>
        {
            alertObj?.title &&
            <IQMAlert
                {...alertObj}
                closable={true}
                longDescriptionText=''
                longDescription=''
                style={alertStyles}
                close={onClose}
            />
        }
    </>
}