//@ts-nocheck
import { createContext , useReducer} from "react";

interface stateType{
    isError:Boolean,
    message:String,
    description: String ,
    type?:string
}
interface actionType{
    type:String,
    payload:{
        isError:Boolean,
        message:String,
        description: String,
        type?:String,
    }
}
let initialError={
    isError:false,
    message:'',
    description: '',
    type:undefined
}

const ErrorContext = createContext({
  error:initialError,
  setError:(type:String,payload:{isError:Boolean ,message:String , description:String,type?:String})=>{},
  resetError:()=>{}});

const reducer = (state : stateType, action:actionType) => {
    switch (action.type) {
      case "ERROR":
        let errObj =  { message : action.payload.message , description : action.payload.description ,isError : action.payload.isError,type:action.payload.type}
        return {...state , ...errObj }
      default:
        return state;
    }
  };

const ErrorContextProvider = ({ children }) => {
    
    const [error, dispatch] = useReducer(reducer, initialError);

    /**
     * 
     * @param type (String) 'ERROR'
     * @param payload {message:string , description:string}
     */
    const setError = (type:string , payload : {isError:boolean ,message:string , description:string}) => {
        dispatch({ type: type , payload:payload });
      };

      const resetError = ()=>{
        dispatch({ type: 'ERROR' , payload:{isError:false, message:'' , description:'' } });
    }
    
    return (
      <ErrorContext.Provider value={{ error:error , setError : setError , resetError : resetError}}>
        {children}
      </ErrorContext.Provider>
    );
  };

  export {ErrorContextProvider , ErrorContext};