import { AuthEndpoint, LogoutEndpoint } from "../constants";
import {MeResponse} from "./types";

const redirectToLogin = (referrer?: string) => {
  let authEndpoint = `${AuthEndpoint(window.location.origin)}`;

  if (!!referrer) {
    authEndpoint += `&state=${encodeURIComponent(referrer)}`;
  }

  // @ts-ignore
  window.top.location.replace(authEndpoint);
};

const redirectToLogout = (token: string | null) => {
  if (token) {
    // @ts-ignore
    window.top.location.replace(LogoutEndpoint(token, window.location.origin));
  } else {
    redirectToLogin();
  }
};

const getAuthTokenFromQueryString = () => {
  if(window.location.hash) {
    const auth = getAuthState(window.location.hash);
    if (auth && auth.access_token) {
      return auth.access_token;
    }
  }
};

const getAuthState = (stateString: string) => {
  return stateString.replace(/^#/, '')
      .split('&')
      .reduce((curr:any, state: string) => {
        const pair = state.split('=');
        curr[pair[0]] = decodeURIComponent(pair[1]);
        return curr;
      },{});
}

const getReferrerFromQueryString = () => {
  const params = new URLSearchParams(window.location.search);

  if (params.has("state")) {
    return decodeURIComponent(params.get("state") as string);
  } else {
    return null;
  }
};

const convertExpiresToMilliseconds =(expires_in: number): number => {
  const date = new Date();
  date.setSeconds(Number(date.getSeconds() + expires_in))
  return date.getTime()
}

export { redirectToLogin, redirectToLogout, getAuthTokenFromQueryString, getReferrerFromQueryString, convertExpiresToMilliseconds };
