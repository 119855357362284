import { useContext, useCallback } from "react";
import { TokenByCodeEndpoint } from "../../constants";
import { AuthContext } from "../auth-context";
import { Token } from "../types";
import axios from "axios";
import { convertExpiresToMilliseconds } from "../utilities";

const TOKEN = "token";
const OAUTH_TOKEN = "oauthToken";

const storeToken = (token: Token) => {
  localStorage.setItem(TOKEN, JSON.stringify(token));
  localStorage.setItem( OAUTH_TOKEN, token.value);
  sessionStorage.setItem(OAUTH_TOKEN, token.value);
};

const getAuthToken = () =>  {
  return localStorage.getItem(OAUTH_TOKEN);
}

const useToken = () => {
  const { dispatch } = useContext(AuthContext);

  const setToken = useCallback(
    (token: Token) => {
      storeToken(token);
      dispatch({ type: "login" });
    },
    [dispatch]
  );

  const clearToken = useCallback(() => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(OAUTH_TOKEN);
    sessionStorage.removeItem(OAUTH_TOKEN);
    dispatch({ type: "logout" });
  }, [dispatch]);

  const getToken = useCallback(async (): Promise<Token> => {
    // Try to grab a token from localStorage
    const serializedToken = localStorage.getItem(TOKEN);
    if (!serializedToken) {
      return Promise.reject(new Error("No token found in localStorage"));
    }

    // Try to deserialize the token from localStorage
    let token = null;
    let authToken = null;

    try {
      token = JSON.parse(serializedToken) as Token;
      authToken = token.value;
    } catch {
      return Promise.reject(new Error("Unable to parse serialized token from localStorage"));
    }

    // If the token hasn't expired, return it

    if (token.expires_in > Date.now()) {
      // re-store the token - will put it back into session storage for backwards compatability
      storeToken(token);
      return Promise.resolve(token);
    }

    // If the token has expired, try to refresh it
    try {
      const response = await axios.get(TokenByCodeEndpoint(authToken));

      const refreshedToken = response.data as Token;
      refreshedToken.value = authToken;
      refreshedToken.expires_in = convertExpiresToMilliseconds(refreshedToken.expires_in);
      setToken(refreshedToken);
      return Promise.resolve(refreshedToken);
    } catch {
      clearToken();
      return Promise.reject(new Error("Unable to get token from refresh token"));
    }
  }, [setToken, clearToken]);

  return {
    getToken,
    setToken,
    clearToken,
  };
};

export { useToken, getAuthToken };
