import { useContext, useMemo } from "react";
import { AuthContext } from "../auth-context";

const useSession = () => {
  const { state } = useContext(AuthContext);
  const session = useMemo(
    () => ({
      session: state.me
        ? {
            user: {
              id: state.me?.Id,
              firstName: state.me?.FirstName,
              lastName: state.me?.LastName,
              email: state.me?.Email,

            },
            brandingLogo: state.brandingLogo,
          }
        : undefined,
    }),
    [state.me, state.brandingLogo]
  );

  return session;
};

const useCompany = () => {
    const { state } = useContext(AuthContext);
    const company = useMemo(
        () => ({
            parent: state.parentEntity
                ? {
                    company: {
                        name: state.parentEntity?.Name,
                        id:  state.parentEntity?.Id,
                    }
                }
                : undefined,
        }),
        [state.parentEntity]
    );

    return company;
};
export { useSession, useCompany };
