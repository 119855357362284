import React, {useEffect, useContext } from "react";
import { useToken, AuthContext } from "../auth";
import { addInteropHandler, removeInteropHandler } from "@iqmetrix/host-interop";
import {IHandlerProps} from "./HandlerProps";

const InteropHandlers: React.FunctionComponent<IHandlerProps> = (props: any) => {
  const { getToken } = useToken();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    const authHandler = addInteropHandler<void, object>({
      // @ts-ignore
      target: window.top,
      name: "get-authentication-token",
      version: "1",
      requestHandler: async () => {
        const token = await getToken();
        return token.value;
      },
    });

    return () => {
      removeInteropHandler({ listener: authHandler });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  return <>{props.children}</>;
};

export { InteropHandlers };
