import { useCallback, useContext } from "react";
import { useToken } from "./use-token";
import {
  convertExpiresToMilliseconds,
  getAuthTokenFromQueryString,
  getReferrerFromQueryString,
  redirectToLogin
} from "../utilities";
import { useAsyncError } from "../../utilities";
import axios from "axios";
import { TokenByCodeEndpoint } from "../../constants";
import { Token } from "../types";
import { NotificationContext } from "../../components/utils/notificationContext";
import { getErrorObject } from "../../services/http.service";

const useLogin = () => {
  const { setToken } = useToken();
  const { throwErrorOnRender } = useAsyncError();
  const notificationContext = useContext(NotificationContext);

  const login = useCallback(async () => {
    const authToken = getAuthTokenFromQueryString();
    if (!authToken) {
      redirectToLogin(`${window.location.pathname}${window.location.hash}`);
    } else {
      try {
        let referrer = getReferrerFromQueryString() ?? "/";
        window.history.replaceState({}, document.title, referrer);
        const response = await axios.get(TokenByCodeEndpoint(authToken));
        const token = response.data as Token;
        token.value = authToken;
        token.expires_in = convertExpiresToMilliseconds(token.expires_in);
        setToken(token);
      } catch (e: any) {
        // throwErrorOnRender(new Error("Unable to get token from code"));
        const errorObj: any = getErrorObject(e);
        notificationContext.setNotification({ ...errorObj })
      }
    }
  }, [setToken, throwErrorOnRender]);

  return { login };
};

export { useLogin };
