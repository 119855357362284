//@ts-nocheck
import { createContext, useReducer } from "react";

interface stateType {
    message: String,
    description: String,
}

let initialVal: stateType = {
    message: '',
    description: '',
}

const SuccessContext = createContext({
    success: initialVal,
    setSuccess: (message: String, description: String) => { },
    resetSuccess: () => { },
});

const reducer = (state: stateType, action: stateType) => {
    let errObj = { message: action.message, description: action.description };
    return { ...state, ...errObj };
};

const SuccessContextProvider = ({ children }) => {
    const [success, dispatch] = useReducer(reducer, initialVal);

    const setSuccess = (message: string, description: string ) => {
        dispatch({ message, description });
    };

    const resetSuccess = () => {
        dispatch({ message: '', description: '' });
    }

    return (
        <SuccessContext.Provider value={{ success: success, setSuccess: setSuccess, resetSuccess: resetSuccess }}>
            {children}
        </SuccessContext.Provider>
    );
};

export { SuccessContextProvider, SuccessContext };