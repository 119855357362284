import React, {useEffect, useContext } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { AuthContext, useLogin, useToken} from "../auth";
import { useHttp } from "../http";
import { useAsyncError } from "../utilities";
import * as Sentry from "@sentry/react";
import {IHandlerProps} from "./HandlerProps";

const SpinContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const COMPANY_ID = "companyId";
const storeCompanyId = (companyId: number) => {
  localStorage.setItem(COMPANY_ID, String(companyId));
  sessionStorage.setItem(COMPANY_ID, String(companyId));
};

const SessionHandler: React.FunctionComponent<IHandlerProps> = (props: any) => {
  const { state, dispatch } = useContext(AuthContext);
  const { httpClient } = useHttp();
  const { throwErrorOnRender } = useAsyncError();
  const { getToken } = useToken();
  const { login } = useLogin();

  useEffect(() => {
    const attemptLogin = async () => {
      try {
        await getToken();
        dispatch({ type: "login" });
      } catch {
        await login();
      }
    };

    attemptLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getMe = async () => {
      try {
        const me = await httpClient.getMe();
        dispatch({ type: "set_me", me });

        Sentry.setUser({ id: me.Id.toString() });
      } catch (e) {
        //  throwErrorOnRender(e);
      }
    };

    const getParentEntity = async () => {
      try {
        const parentEntity = await httpClient.getParentEntity();
        dispatch({ type: "set_parententity", parent:parentEntity });
        storeCompanyId(parentEntity.Id)

      } catch (e) {
        // throwErrorOnRender(e);
      }
    };

    if (state.isLoggedIn && !state.me) {
      getMe();
      getParentEntity()
    }
  }, [httpClient, state.me, state.isLoggedIn, dispatch, throwErrorOnRender]);

  if (!state.isLoggedIn || !state.me) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <>{props.children}</>;
};

export { SessionHandler };
