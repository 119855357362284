import { INotification } from "@iqm-ui-library/booster";
import { Typography } from "antd";
import { createContext, useReducer, useState } from "react";

interface INotificationCustom extends INotification {
    additionalDescription?: string[]
}

const initialNotification: INotification = {
    placement: "bottomRight",
    message: '',
    description: '',
    id: 0
}

const NotificationContext = createContext({
    notification: initialNotification,
    setNotification: (notification: INotification) => { },
    resetNotification: () => { }
});

const reducer = (state: INotification, payload: INotification) => {
    return { ...state, ...payload }
}

const NotificationContextProvider = ({ children }: any) => {
    const { Text, Paragraph } = Typography;
    const [notification, dispatch] = useReducer(reducer, initialNotification)

    const setNotification = (notification: INotificationCustom) => {
        const tempDescription = <>
            {
                <>
                    {/* <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}> */}
                    {
                        notification?.additionalDescription?.length ?
                            <ul style={{ paddingLeft: 0 }}>
                                {
                                    notification?.additionalDescription?.map(item => <li key={item}>{item}</li>)
                                }
                            </ul> :

                            <Text>{notification.description}</Text>
                    }
                    {/* </Paragraph> */}

                </>
            }
        </>
        notification.description = tempDescription;
        notification.message && dispatch(notification);
    }

    const resetNotification = () => {
        dispatch(initialNotification);
    }

    return (<NotificationContext.Provider value={{ notification: notification, setNotification: setNotification, resetNotification: resetNotification }}>
        {children}
    </NotificationContext.Provider>)
}

export { NotificationContextProvider, NotificationContext }
