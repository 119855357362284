import { useContext } from "react";
import { NotificationContext } from "../utils/notificationContext";
import { IQMNotification } from "@iqm-ui-library/booster";

export const CustomNotification = () => {
    const notificationContext = useContext(NotificationContext);
    const onClose = () => {
        notificationContext.resetNotification();
    }
    return <>
        {<IQMNotification {...notificationContext.notification} onClose={onClose}></IQMNotification>}
    </>
}
