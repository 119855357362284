import { createContext, useState } from "react"

const LoaderContext = createContext({
    loader: false,
    message: '',
    setLoader: (loading: boolean, message?: string) => {}
});

const LoaderContextProvider = ({children}: any) => {
    const [loader, setLoaderFn] = useState(false)
    const [message, setMessage] = useState('')

    const setLoader = (loading: boolean, message: string = 'Loading...') => {
        setLoaderFn(loading)
        setMessage(message)
    }

    return <LoaderContext.Provider value={{loader: loader, message: message, setLoader: setLoader}}>{children}</LoaderContext.Provider>
}

export {LoaderContextProvider, LoaderContext}