import {IQMDropdown} from "@iqm-ui-library/booster/antd/IQMDropdown";
import {IQMTable} from "@iqm-ui-library/booster";
import React, {useEffect, useRef, useState} from "react";
import {ReconciliationService} from "../../services/reconciliation.service";
import {Button, Col, DatePicker, Flex, Form, Input, InputRef, Row, Space} from 'antd';
import {columns} from "./allReconciTableConstant";
import {ReconciliationsPagedResponse, Records} from "./allReconciliation.interface";
import {PaginationComponent} from "../pagination/pagination"
import './allReconciliation.css'
import {ColumnType, FilterConfirmProps} from "antd/es/table/interface";
import {FilterFilled} from "@ant-design/icons";
import {dateFormat} from "../utils/commonFunctions";

export enum dateEnum {
    All = "All",
    Yesterday = "Yesterday",
    Last7days = "Last 7 days",
    Last15days = "Last 15 days",
    Last30days = "Last 30 days",
    Custom = "Custom"
}

function Highlighter(props: { searchWords: string[], autoEscape: boolean, textToHighlight: string, highlightStyle: { padding: number; backgroundColor: string } }) {
    return null;
}

export function AllReconciliations(props: any) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [columnData, setColumnData] = useState(columns);
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setFilterString(undefined);
        setSearchText('');
    };

    const resetData = () => {
        setDataSource([]);
        setConsumedData(0);
        setItemCount(0);
        setPagination({top: 10,current: 1})
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Filter ${dataIndex}`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        resetData();
                        setFilterString((selectedKeys[0])?.toString());
                        close()
                        confirm()
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>  {
                            resetData();
                            setFilterString((selectedKeys[0])?.toString());
                            close()
                            confirm()
                        }}
                        icon={ <FilterFilled />}
                        size="small"
                        style={{ width: 90 }}
                    >Filter
                    </Button>
                    <Button
                        onClick={() => {
                            resetData();
                            clearFilters && handleReset(clearFilters);
                            close()
                            confirm()
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    interface DataType {
        key: string;
        name: string;
    }

    type DataIndex = keyof DataType;

    useEffect(() => {
        setColumnData((prev: any) => (
            prev.map((column: any) => {
                if(column.dataIndex === 'name') {
                    return {
                        ...column,
                        ...getColumnSearchProps('name'),
                    }
                }
                return column;
            })
        ));
    },[columns]);

    let today:any
    today = new Date();
    const last7DayDate = new Date(today)
    const reconciliationService = new ReconciliationService();
    const { RangePicker } = DatePicker;

    const [dataSource, setDataSource] = useState<ReconciliationsPagedResponse['listOfReconciliation']>([]);
    const [loading, setLoading] = useState(false);
    const [filterDateOptions, setfilterDateOptions] = useState<any>([]);
    const [selectedFilterOpt, setSelectedFilterOpt] = useState()
    const [isOpen, setOpen] = useState(false)
    const [periodRangeEndUtc, setPeriodRangeEndUtc]= useState<any>(formatDate(today))
    const last7Days = new Date(last7DayDate.setDate(last7DayDate.getDate() - 7))
    const [periodRangeStartUtc, setPeriodRangeStartUtc]= useState(formatDate(last7Days))
    const [filterString, setFilterString]=useState<string>()
    const [itemCount, setItemCount] = useState(0)
    const [pagination, setPagination] = useState({top:10, current:1})
    let [consumedData, setConsumedData] = useState(0)
    const [pageSizeCount, setPageSizeCount] = useState(5)

    const [isAPICallVal, setIsAPICall] = useState(0)
    const [prevPage, setPrevPage] = useState(1) ;
    let fetchNewItems :number;
    let startDate :any;
    let endDate : any;
    let userInfo :any[] =[];
    
    /* Fetch All Reconciliation API */
    async function getAllReconciliation(params?:any) {
        if(params.top>100){
            let numberOfApis = Math.ceil(params.top/100);
            let lastTop = params.top%100;
            let top = 100;
            let skip = params.skip;
            let lastPage = (pagination.current == Math.ceil(itemCount/pageSizeCount));
            while(lastPage ? (numberOfApis>0) : (numberOfApis>=0)){
                if(numberOfApis===1){
                    top = lastTop;
                  }
                  numberOfApis--
              await getAllReconciliation({
                top: top,
                skip: skip,
                nameFilter: params.nameFilter
              });
              skip = skip+100;
            }
          }
          else{
            let topByCurrentPage = pagination.top/pagination.current
              if( ( ( pagination.current >= prevPage ) && (dataSource.length !== +itemCount) && (topByCurrentPage < pageSizeCount)) || (pagination.current === 1  && dataSource.length === 0)){
                const val = await reconciliationService.getReconciliation(params);
                setIsAPICall((isAPICallVal || 0) + 1)
                setItemCount(Number(val?.metadata.totalCount) || 0)
                const updatedRecords = val?.records
                if(updatedRecords){
                    /*To get username */
                    let tempParams = ''
                    let tempIds:any = []
                    updatedRecords?.map((item:any)=>{
                        if( (item?.lastUpdatedById) && !tempIds.includes(item?.lastUpdatedById)){
                            tempIds.push(item?.lastUpdatedById)
                        }
                    });
                    tempIds.map((lastUpdatedById:any)=>{
                        tempParams = tempParams  ? tempParams+'&userId='+lastUpdatedById : '?userId='+lastUpdatedById
                    })
                    if(tempParams){
                        userInfo  = await reconciliationService.getUserInfo(tempParams)
                    }
                    let updatedUserRecord : any
                    updatedUserRecord = updatedRecords?.map((it: any, index: number) => ({
                        ...it,
                        key: it?.id, //Id to create key
                        ...getUsers(it?.lastUpdatedById, userInfo)
                    }));
                    setDataSource((prevState)=> ([...prevState, ...updatedUserRecord]));
                }
            }
          }
    }

    useEffect(() => {
        /*check for last page and fetch */
        if ((itemCount > 0) && dataSource.length >= +itemCount) return
        if (Math.ceil(itemCount / pageSizeCount) === pagination.current) {
            fetchNewItems = itemCount - consumedData
        } else {
            fetchNewItems = Math.abs(Number(pageSizeCount * (pagination.current - prevPage)));
        }
        if (!isOpen) {
            getAllReconciliation({ periodRangeStartUtc, periodRangeEndUtc, top: fetchNewItems ? fetchNewItems : pagination.top, skip: consumedData, nameFilter: filterString });
        }
    }, [pagination.current, periodRangeStartUtc, (!isOpen), ((itemCount > 0) && (dataSource.length >= +itemCount)), filterString]);

    /* Onclick on seeDetails trigger handleMenu of home page to render details page*/
    const seeDetails = (e: any, item: any) => {
        props.parentHandler(e, item)
    }

    /* Set onClick method in dataSource on load */
    useEffect(() => {
        dataSource.forEach((item: Records) => {
            item.onClick = seeDetails.bind(item, 'RD')
            item.action = "See details"
            item.reconciliationPeriod = `${dateFormat(item?.periodStartDateUtc)} - ${dateFormat(item?.periodEndDateUtc)}`
            item.unmatchedItemCountUI = item.unmatchedItemCountUI ? item.unmatchedItemCountUI : `${item.unmatchedItemCount}/${item.totalItemCount} `;
            item.reconciledItemCountUI = item.reconciledItemCountUI? item.reconciledItemCountUI : `${item.reconciledItemCount}/${item.totalItemCount} `
        })
        setDataSource(dataSource?dataSource:[])
        const filterDateOptions = (Object.entries(dateEnum).map(val => {
            return {
                label: val[1],
                value: val[1]
            }
        }));
        setfilterDateOptions(filterDateOptions)
        setConsumedData(dataSource.length)
    }, [dataSource]);

    function getUsers (id:number, userInfo:any) {
        const user = userInfo?.users?.find((it :any) => it?.id == id)
        return {
            lastUpdatedByName : (user?.firstName || '')+" "+(user?.lastName || '')
        }
    }

    /*Format date in MM/DD/YYYY (UTC)*/
    function formatDate(tempDate:any){
        return tempDate && dateFormat(tempDate);
    }

    /*clear state when filter selected*/
    function clearState(){
        setDataSource([]);
        setConsumedData(0)
        setIsAPICall(0)
        setPrevPage(1)
        setPagination((prevState) => ({...prevState, ...{current: 1}}))
    }

    /* Set start and end date period and pass these params to reconcile API call */
    function setStartAndEndPeriod(value:any){
        /*Set Start date */
        if(value === dateEnum.Custom) {
            setOpen(true)
        } else {
            /*Set End date as of today's*/
            setPeriodRangeEndUtc(formatDate(today))

            /*Set Start date */
            if(value === dateEnum.Yesterday){
                today.setDate(today.getDate() - 1);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last7days){
                today.setDate(today.getDate() - 7);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last15days){
                today.setDate(today.getDate() - 15);
                setPeriodRangeStartUtc(formatDate(today))
            }else if(value === dateEnum.Last30days){
                today.setDate(today.getDate() - 30);
                setPeriodRangeStartUtc(formatDate(today))
            } else if(value === dateEnum.All || value === undefined){
                setPeriodRangeStartUtc(undefined)
                setPeriodRangeEndUtc(undefined)
            }
            clearState();
        }
    }

    /*On change filter and display items */
    const handlePeriodChange = (value: any) => {
        /*enum use state set value*/
        setSelectedFilterOpt(value)
        /*set start end period range */
        setStartAndEndPeriod(value);
    };

    /*On selecting date picker set date range */
    function onCalendarSelection(dates: any, dateStrings: any[], info: any){
        startDate = dateStrings[0];
        endDate = dateStrings[1];
        if(startDate && endDate) {
            clearState();
            setPeriodRangeStartUtc(startDate);
            setPeriodRangeEndUtc(endDate)
            setOpen(true)
        }
    }

    /*On page change*/
    function onTableChange(paginationFromComp:any, filters?:any, sort?:any){
        setPrevPage(pagination.current)
        if(pageSizeCount!==paginationFromComp.newPageSize){
          setPageSizeCount(paginationFromComp.newPageSize);
          setPagination((previousValues) => ({ ...previousValues, ...{ current: 1 } }))
        }else{
          setPagination((previousValues) => ({ ...previousValues, ...{ current: paginationFromComp.page } }))
        }
        
      }
    return <>
  <Form initialValues={{ periodSelect: dateEnum.Last7days}}>
        <Flex wrap gap="small">
            <Col>
            <IQMDropdown
                required={false}
                value={selectedFilterOpt}
                onChange={(e: any) => handlePeriodChange(e)}
                name="periodSelect"
                options={filterDateOptions}
                showSearch={false}
                width={320}
                placeholder="Select a Period"
                allowClear={true}
            /></Col>
            <Col>{ selectedFilterOpt === dateEnum.Custom &&
                <RangePicker autoFocus={true} allowClear={true} disabledDate={d => !d
                    || d.isAfter(today)}  onOpenChange={setOpen} style={{marginLeft:'20px'}} onCalendarChange={onCalendarSelection} format={'MM/DD/YYYY'}/>
            }</Col>
        </Flex>
    </Form>


        <IQMTable
            dataSource={dataSource?dataSource:null}
            expandable={true}
            columns={columnData}
            pageSize={pageSizeCount}
            current={pagination.current}
            total={itemCount}
            tableHeader={""}
            dropdown={false}
            loading={loading}
            scroll={{x:'scroll'}}
            footer={() =><>
                <PaginationComponent current={pagination.current} total={itemCount} pageSize={pageSizeCount} onChange={onTableChange} showQuickJumper={true} showSizeChanger={true} pageSizeOptions={[5,10]}/></>
            }
        />
    </>;
}
