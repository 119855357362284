import {TableColumn} from "@iqm-ui-library/booster";
import {dateFormat} from "../utils/commonFunctions";
import { Tag } from "antd";
import { IReconciliationStatusColors } from "../reconciliationDetails/reconciliationDetailsPage.interface";

export const columns: TableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    order: 1,
    type: "text",
  },
  {
    title: "Created date",
    dataIndex: "createdDateUtc",
    order: 2,
    type: "text",
    render: (text) => dateFormat(text)
  },
  {
    title: "Reconciliation period",
    dataIndex: "reconciliationPeriod",
    order: 3,
    type: "text",
  },
  {
    title: "Status",
    dataIndex: "status",
    order: 4,
    type: "tag",
    render: (text) => {
      return <Tag color={IReconciliationStatusColors[text as keyof typeof IReconciliationStatusColors]}>{text}</Tag>;
    }
  },
  {
    title: "Unmatched items",
    dataIndex: "unmatchedItemCountUI",
    order: 5,
    type: "text",
  },
  {
    title: "Reconciled items",
    dataIndex: "reconciledItemCountUI",
    order: 6,
    type: "text",
  },
  {
    title: "Last edited by",
    dataIndex: "lastUpdatedByName",
    order: 7,
    type: "text",
  },
  {
    title: "Last update",
    dataIndex: "lastUpdatedDateUtc",
    order: 8,
    type: "text",
    render: (text) => dateFormat(text)
  },
  {
    title: "",
    dataIndex: "action",
    order: 9,
    type: "action",
  },
];
