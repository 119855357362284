import {ParentEntity, MeResponse, useToken} from "../auth";
import axios from "axios";
import { meEndpoint, parentEntityEndpoint } from "../constants";
import { useCallback, useContext, useMemo } from "react";
import { getErrorObject } from "../services/http.service";
import { NotificationContext } from "../components/utils/notificationContext";

const useHttp = () => {
  const { getToken } = useToken();
  const notificationContext = useContext(NotificationContext);

  const getMe = useCallback(async (): Promise<MeResponse> => {
    const token = await getToken();
    const headers = { Authorization: `Bearer ${token.value}` };
    try {
      const response = await axios.get(meEndpoint(), { headers });
      return response.data as MeResponse;
    } catch (e:any) {
      const errorObj: any = getErrorObject(e);
      notificationContext.setNotification({...errorObj})
      return e;
    }
  }, [getToken]);

  const getParentEntity = useCallback(async (): Promise<ParentEntity> => {
    const token = await getToken();
    const headers = { Authorization: `Bearer ${token.value}` };
    try {
      const response = await axios.get(parentEntityEndpoint(), { headers });
      return response.data as ParentEntity;
    } catch (e:any) {
      const errorObj: any = getErrorObject(e);
      notificationContext.setNotification({...errorObj})
      return e;
    }
  }, [getToken]);

  const httpClient = useMemo(
    () => ({
      getMe,
      getParentEntity,
    }),
    [getMe,getParentEntity]
  );

  return { httpClient };
};

export { useHttp };
